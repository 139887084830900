<template>
  <div class="applet">
    <el-card shadow="never">
      <el-descriptions class="margin-top" :column="3" :size="$store.state.size" border>
        <template slot="title">
          <i class="el-icon-setting"></i><strong> 小程序配置</strong>
        </template>
        <template slot="extra">
          <el-button v-if="hasPermission('2-3-a')" type="primary" :size="$store.state.size" @click="save">保存</el-button>
        </template>
        <el-descriptions-item>
          <template slot="label">
            <i class="iconfont icon-lock"></i>
            AppId
          </template>
          <el-input
              placeholder="请输入AppId"
              :size="$store.state.size"
              v-model="appId"
              clearable>
          </el-input>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="iconfont icon-lock"></i>
            AppSecret
          </template>
          <el-input
              placeholder="请输入AppSecret"
              :size="$store.state.size"
              v-model="appSecret"
              clearable>
          </el-input>
        </el-descriptions-item>
      </el-descriptions>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "applet",
  data(){
    return{
      appId:'123456',
      appSecret:'123456'
    }
  },
  methods:{
    //判断权限是否存在
    hasPermission(item){
      return this.$common.hasPermission(item);
    },
    save(){
      this.$message({
        message: '保存成功！！！',
        type: 'success'
      });
    }
  }
}
</script>

<style lang="scss">
.applet{

}
</style>